/* Customize your main colors in :root variables */
:root {
  /* --main-background-color: #343A50; */
  --main-background-color: #000000;
  --card-background-color: #51576D00;
  --card-background-lighter-color: #7A7F92;
  --main-text-color:#F7F6F4;
  --main-text-color-transp:#F7F6F440;
  --title-text-color:#3CBA8B;
}

body{
  background-color:var(--main-background-color);
  font-family: 'Remake';
  font-weight: 350;
  font-size: 0.85em;
}

